'use strict';

class CommunicationRootController {
  private state;

  constructor(
    private $scope,
    private CommunicationService,
    private NotificationCenter,
    private CommunicationAPI,
    private UserService,
    private API_END_POINT,
  ) {}

  $onInit() {
    this.state = this.CommunicationService.getState();
    this.$scope.$on('communication:message:received', (_, _payload) => {
      this.$scope.$digest();
    });
    this.$scope.$on('communication:message:read', (_, _payload) => {
      this.$scope.$digest();
    });
    this.$scope.$on('communication:message:typing_start', (_, _payload) => {
      this.$scope.$digest();
    });
    this.$scope.$on('communication:message:typing_stop', (_, _payload) => {
      this.$scope.$digest();
    });

    this.NotificationCenter.native.requestPermissions();
  }
}

angular.module('app.communication').component('communicationRoot', {
  templateUrl: 'communication/components/communication-root.html',
  controller: CommunicationRootController,
  controllerAs: '$comRootCtrl',
});

'use strict';

class MessageSenderController {
  private userId;
  private organizationId;
  private displayText;
  private short: Boolean;

  private user;
  private organization;
  private $promise;

  constructor(
    private $q,
    private CommunicationMemberCache,
    private _,
  ) {}

  $onInit() {
    this.$promise = this.$q
      .all({
        organization: this.organizationId
          ? this.CommunicationMemberCache.getMember('org', this.organizationId)
          : this.$q.resolve(),
        user: this.CommunicationMemberCache.getMember('user', this.userId),
      })
      .then(({ user, organization }) => {
        this.user = user;
        this.organization = organization;
      });
  }

  get displayTextOrg() {
    if (this.organization && this.user) {
      const organizationId = this.organization.id;
      const userDisplayName = this._.joinIfPresent(
        ' ',
        this._.get(this.user, 'first_name'),
        this._.get(this.user, 'last_name'),
      );
      const userOrganizationRole: any = this._.find(this.user.roles, { id: organizationId });
      if (!userOrganizationRole) {
        return this.organization.name || 'Unknown Organization';
      }

      const roleDisplay = userOrganizationRole.roles.map((role) => role.display).join(', ');
      return `${this.organization.name} - ${userDisplayName} (${roleDisplay})`;
    }

    if (this.organization) {
      return this.organization.name;
    }
  }

  get displayTextUser() {
    if (this.user) {
      const userDisplayName = this._.joinIfPresent(
        ' ',
        this._.get(this.user, 'first_name'),
        this.short ? '' : this._.get(this.user, 'last_name'),
      );

      return userDisplayName || 'Unknown User';
    }
  }
}

angular.module('app.communication').component('messageSender', {
  templateUrl: 'communication/components/message-sender.html',
  controller: MessageSenderController,
  bindings: {
    short: '<',
    userId: '<',
    organizationId: '<',
  },
});

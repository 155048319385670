'use strict';
var InboxController = (function () {
    function InboxController($timeout, $http, $scope, $state, $filter, UserService, CommunicationAPI, CommunicationService, Paginator, WakeUpTimer) {
        this.$timeout = $timeout;
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.$filter = $filter;
        this.UserService = UserService;
        this.CommunicationAPI = CommunicationAPI;
        this.CommunicationService = CommunicationService;
        this.Paginator = Paginator;
        this.WakeUpTimer = WakeUpTimer;
        this.user = this.UserService.getUser();
        this.state = this.CommunicationService.getState();
    }
    InboxController.prototype.$onInit = function () {
        var _this = this;
        this.inboxKeys = _.keys(this.state.inboxes.toJS());
        this.oldestTimestamp = '';
        this.CommunicationService.refreshInboxStats();
        this.paginator = this.Paginator.getInstance({
            strategy: 'PaginatorNormalStrategy',
            limit: 20,
            pagingFunction: function (_a, _appendResult) {
                var _skip = _a._skip, limit = _a.limit;
                var shouldAppendResult = _this.oldestTimestamp ? true : false;
                return _this.CommunicationAPI.fetchChannels(_this.inboxKey, { before_ts: _this.oldestTimestamp, limit: limit }, shouldAppendResult)
                    .then(function (channels) {
                    var oldestCh = _.first(_.orderBy(channels, 'last_message_ts'));
                    _this.oldestTimestamp = oldestCh ? oldestCh.last_message_ts : '';
                    return channels;
                })
                    .catch(angular.noop);
            },
        });
    };
    InboxController.prototype.$onChanges = function (changesObj) {
        if (changesObj && changesObj.inboxes && changesObj.inboxes.currentValue) {
            var immutableChannels = changesObj.inboxes.currentValue.get(this.inboxKey);
            if (immutableChannels) {
                this.channels = immutableChannels
                    .sortBy(function (ch) { return ch.get('last_message_ts'); })
                    .reverse()
                    .toJS();
            }
        }
        if (changesObj &&
            changesObj.isSocketReady &&
            !changesObj.isSocketReady.isFirstChange() &&
            changesObj.isSocketReady.currentValue &&
            changesObj.isSocketReady.currentValue !== changesObj.isSocketReady.previousValue) {
            this.$onInit();
        }
    };
    InboxController.prototype.leaveChannel = function (channel, inboxKey) {
        var _this = this;
        var channelIndex = _.findIndex(this.channels, function (ch) { return ch.id + '' === channel.id + ''; });
        var nextIndex = channelIndex > 0 ? channelIndex - 1 : channelIndex + 1;
        var nextChannel = this.channels[nextIndex];
        this.CommunicationAPI.leaveChannel(channel, inboxKey).then(function () {
            if (nextChannel) {
                _this.$state.go('root.communication.inbox.channel', { channelId: nextChannel.id });
            }
            else {
                _this.$state.go('root.communication.inbox');
            }
        });
    };
    return InboxController;
}());
angular.module('app.communication').component('inbox', {
    templateUrl: 'communication/components/inbox.html',
    controller: InboxController,
    controllerAs: '$inboxCtrl',
    bindings: {
        inboxKey: '<',
        inboxes: '<',
        isOnline: '<',
        isSocketReady: '<',
    },
});
class ChatComposerController {
  private $communicationAPI;

  private channel;
  private inboxKey;
  private typingUsers;
  private user;

  private byId;
  private newMessage;
  private typingText;

  private _notifyAboutTypingThrottled;
  private _storeDraftMessageThrottled;

  constructor(
    private $element,
    private $timeout,
    private UserService,
    private CommunicationAPI,
    private CommunicationService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    API_END_POINT,
  ) {
    this.byId = {};
    this.$communicationAPI = CommunicationAPI;
    this.newMessage = '';
    this._notifyAboutTypingThrottled = _.throttle(this._notifyAboutTyping, 2000);
    this._storeDraftMessageThrottled = _.throttle(
      this.CommunicationService.storeDraftMessage,
      1000,
    );
  }

  $onInit() {
    this.$timeout(() => {
      this.$element.find('textarea').first().focus();
    }, 200);
  }

  $postLink() {
    this.$timeout(() => {
      const attachBtn = this.$element.find('.attach');
      this.CommunicationService.$flowUploaderInstance.assignBrowse(attachBtn);
    }, 200);
  }

  $onChanges(changesObj) {
    if (changesObj.channel && changesObj.channel.currentValue) {
      const channel = changesObj.channel.currentValue;
      channel.users.forEach((user) => (this.byId[user.id] = user));
      channel.organizations.forEach((org) => (this.byId[org.id] = org));

      this.newMessage = this.CommunicationService.retrieveDraftMessage(channel.id);

      this.$timeout(() => {
        this.$element.find('textarea').first().focus();
      }, 200);
    }

    if (changesObj.typingUsers && changesObj.typingUsers.currentValue) {
      this.typingText = this._getTypingText(changesObj.typingUsers.currentValue);
    }
  }

  sendMessage() {
    if (!this.newMessage) {
      return;
    }

    this._notifyAboutTypingThrottled.cancel();
    this._notifyAboutTyping(false);

    this.$communicationAPI.sendMessage(this.channel, this.newMessage, this.inboxKey);
    this.newMessage = '';
    this.CommunicationService.clearDraftMessage(this.channel.id);
    this._storeDraftMessageThrottled.cancel();
    return false;
  }

  onTextChange() {
    if (this.newMessage) {
      this._notifyAboutTypingThrottled(true);
      this._storeDraftMessageThrottled(this.channel.id, this.newMessage);
    } else {
      this._notifyAboutTypingThrottled.cancel();
      this._notifyAboutTyping(false);
      this.CommunicationService.clearDraftMessage(this.channel.id);
      this._storeDraftMessageThrottled.cancel();
    }
  }

  _notifyAboutTyping(isTyping) {
    this.$communicationAPI.notifyAboutTyping(this.channel, this.inboxKey, isTyping);
  }

  _getTypingText(typingUsers) {
    const organizationId = this.inboxKey.split('org:')[1];

    typingUsers = typingUsers
      .filter((user) =>
        organizationId
          ? !user.organization_id || user.organization_id !== organizationId
          : !!user.organization_id || user.user_id !== this.user._id,
      )
      .map((user) =>
        user.organization_id
          ? _.get(this.byId[user.organization_id], 'name', '')
          : _.get(this.byId[user.user_id], 'first_name', ''),
      );

    if (typingUsers.length) {
      return typingUsers.join(', ');
    }

    return '';
  }

  isDisabled() {
    return !this.CommunicationService.isReady;
  }
}

angular.module('app.communication').component('chatComposer', {
  templateUrl: 'communication/components/chat-composer.html',
  controller: ChatComposerController,
  bindings: {
    user: '<',
    channel: '<',
    inboxKey: '<',
    typingUsers: '<',
  },
});

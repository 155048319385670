var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SPORTCONTRACT_TAGGING_ID = '641036e1516f1df26a770f68';
var RecipientsAutocomplete = (function () {
    function RecipientsAutocomplete($q, CommunicationAPI, _) {
        this.$q = $q;
        this.CommunicationAPI = CommunicationAPI;
        this._ = _;
        this.searchPromise = $q(function (_resolve) { });
        this.staffSuggestions = [];
        this.playerSuggestions = [];
        this.organizationSuggestions = [];
    }
    RecipientsAutocomplete.prototype.$onInit = function () {
        var _this = this;
        if (this.initialySelectedUsers) {
            var selections = (this.selections || []).concat(this.initialySelectedUsers.map(function (item) {
                switch (item.type) {
                    case 'organization':
                    case 'agency':
                        return _this._getOrganizationDisplay(item);
                    case 'team':
                        return _this._getTeamDisplay(item);
                    default:
                        return _this._getUserDisplay(item);
                }
            }));
            this.onSelectionChange({ $selections: selections });
        }
    };
    RecipientsAutocomplete.prototype.select = function ($selection) {
        if (!this.isSelected($selection)) {
            var selections = (this.selections || []).concat([$selection]);
            this.onSelectionChange({ $selections: selections });
        }
    };
    RecipientsAutocomplete.prototype.unselect = function ($selection, $index) {
        var selections = (this.selections || []).filter(function (item, index) { return index !== $index; });
        this.onSelectionChange({ $selections: selections });
    };
    RecipientsAutocomplete.prototype.isSelected = function (item) {
        return (this.selections || []).some(function (selection) { return selection.id === item.id; });
    };
    RecipientsAutocomplete.prototype.searchForSuggestions = function (text) {
        var _this = this;
        if (!text) {
            return;
        }
        var excludeIds = this.excludeIds || [];
        this.searchPromise = this.CommunicationAPI.searchContacts(text).then(function (_a) {
            var users = _a.users, teams = _a.teams, organizations = _a.organizations;
            var userRows = (users || [])
                .map(function (result) { return result._source; })
                .filter(function (user) { return excludeIds.indexOf(user._id) === -1; })
                .map(function (user) { return _this._getUserDisplay(user); })
                .filter(function (user) { return !!user.roles.length; });
            var teamRows = (teams || [])
                .map(function (result) { return result._source; })
                .filter(function (team) { return excludeIds.indexOf(team._id) === -1; })
                .slice(0, 10)
                .map(function (team) { return _this._getTeamDisplay(team); });
            var orgRows = (organizations || [])
                .map(function (result) { return result._source; })
                .filter(function (org) { return excludeIds.indexOf(org._id) === -1; })
                .slice(0, 10)
                .map(function (org) { return _this._getOrganizationDisplay(org); });
            _this.playerSuggestions = userRows.filter(function (user) {
                var organizationRoles = user.roles.filter(function (role) { return /-organization/.test(role.context); });
                return (organizationRoles &&
                    organizationRoles.length &&
                    organizationRoles.every(function (role) { return role.roles.every(function (role) { return role.name === 'player'; }); }));
            });
            _this.staffSuggestions = _.xorBy(userRows, _this.playerSuggestions, 'id');
            _this.organizationSuggestions = orgRows.concat(teamRows);
        });
        return this.searchPromise;
    };
    RecipientsAutocomplete.prototype._getTeamDisplay = function (team) {
        var teamName = this._.get(team, 'name', '');
        var teamLeague = this._.get(team, 'latestTeamStats.league.name', '');
        var teamCountry = this._.get(team, 'country.name', '');
        return {
            id: this._.get(team, '_id', ''),
            type: 'team',
            display: this._.joinIfPresent(' - ', teamName, teamLeague, teamCountry),
            restricted: this.restrictionEnabled && team.restricted,
        };
    };
    RecipientsAutocomplete.prototype._getOrganizationDisplay = function (org) {
        var orgName = this._.get(org, 'name', '');
        var orgCountry = this._.get(org, 'country.name', '');
        return {
            id: this._.get(org, '_id', ''),
            type: 'organization',
            display: this._.joinIfPresent(' - ', orgName, orgCountry),
            restricted: this.restrictionEnabled && org.restricted,
        };
    };
    RecipientsAutocomplete.prototype._getUserDisplay = function (user) {
        var firstName = this._.get(user, 'profile.firstName', '');
        var lastName = this._.get(user, 'profile.lastName', '');
        var fullName = this._.joinIfPresent(' ', firstName, lastName);
        return {
            id: this._.get(user, '_id', ''),
            type: 'user',
            restricted: this.restrictionEnabled && user.restricted,
            organization_id: this._.get(user, 'organization._id', ''),
            user_organization_role: '',
            roles: (user.roles || [])
                .filter(function (role) { return !!role.resourceName; })
                .filter(function (role) { return role.id && role.id !== SPORTCONTRACT_TAGGING_ID; })
                .map(function (role) { return (__assign(__assign({}, role), { displayText: role.functions && role.functions.length
                    ? role.functions.join(', ')
                    : role.roles.map(function (r) { return r.display; }).join(', ') })); }),
            display: fullName,
            licenses: user.profile.licenses,
        };
    };
    return RecipientsAutocomplete;
}());
angular.module('app.communication').component('recipientsAutocomplete', {
    templateUrl: 'communication/components/recipients-autocomplete.html',
    controller: RecipientsAutocomplete,
    bindings: {
        selections: '<',
        excludeIds: '<',
        restrictionEnabled: '<',
        placeholder: '@',
        onSelectionChange: '&?',
        initialySelectedUsers: '<?',
    },
});
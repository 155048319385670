'use strict';

class NewChatController {
  private modalInstance;

  private selectedInbox;
  private inboxKeys;
  private user;

  private selections;
  private message;
  private subject;

  private errorMessage;
  private isBusy;

  constructor(
    private CommunicationAPI,
    private CommunicationService,
    private UserService,
  ) {
    this.message = '';
    this.subject = '';
    this.errorMessage = '';
    this.isBusy = false;
  }

  $onInit() {
    this.user = this.UserService.getUser();
    this.inboxKeys = _.keys(this.CommunicationService.getState().inboxes.toJS());
    this.selectedInbox =
      this.CommunicationService.getState().currentInbox ||
      this.CommunicationService.getPrivateInboxKey();
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  send() {
    if (!this.selections || !this.selections.length || !this.subject || !this.message) {
      return;
    }

    const users = this.selections.filter((selection) => selection.type === 'user');
    const organizations = this.selections.filter(
      (selection) =>
        selection.type === 'team' ||
        selection.type === 'organization' ||
        selection.type === 'agency',
    );

    this.isBusy = true;

    this.CommunicationAPI.createChannel(
      this.selectedInbox,
      users,
      organizations,
      this.subject,
      this.message,
    )
      .then((newChannel) => {
        this.isBusy = false;
        this.errorMessage = '';
        this.modalInstance.close({ inboxKey: this.selectedInbox, channel: newChannel });
      })
      .catch((err) => {
        this.isBusy = false;
        this.errorMessage = (err && err.data) || 'Failed to create converstation!';
      });
  }
}

angular.module('app.communication').component('newChat', {
  templateUrl: 'communication/components/modals/new-chat.html',
  controller: NewChatController,
  bindings: {
    modalInstance: '<',
    initialySelectedUsers: '<?',
  },
});

'use strict';

class InboxSelectorController {
  private inboxKeys;
  private user;
  private inboxUnreadCount;
  private currentInbox;
  private onInboxSelected;

  private inboxes;

  private organizationInboxes;
  private privateInbox;

  $onInit() {
    this.inboxes = this.inboxKeys.map((inboxKey) => {
      const [inboxType, inboxId] = inboxKey.split(':');

      return {
        id: inboxType === 'user' ? 'private' : inboxId,
        inboxKey,
      };
    });

    this.privateInbox = this.inboxes.find((inbox) => inbox.id === 'private');
    this.organizationInboxes = this.inboxes.filter((inbox) => inbox.id !== 'private');
  }

  onInboxSelect($event, inboxKey) {
    if (typeof this.onInboxSelected === 'function') {
      $event.preventDefault();
      this.onInboxSelected({ $selected: inboxKey });
    }
  }
}

angular.module('app.communication').component('inboxSelector', {
  templateUrl: 'communication/components/inbox-selector.html',
  controller: InboxSelectorController,
  bindings: {
    currentInbox: '<',
    inboxKeys: '<',
    user: '<',
    inboxUnreadCount: '<',
    onInboxSelected: '&?',
  },
});
